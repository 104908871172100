<template>
<div>
  <el-row>
    <el-col>
      <head-layout
        :head-title="$t('cip.plat.sys.onlineUser.title.indexHeadTitle')">
      </head-layout>
    </el-col>
  </el-row>

  <grid-head-layout
    ref="gridHeadLayout"
    :search-columns="searchColumns"
    @grid-head-search="gridHeadSearch"
    @grid-head-empty="gridHeadEmpty"
  ></grid-head-layout>
  <grid-layout
    ref="gridLayOut"
    :grid-row-btn="gridRowBtn"
    :table-options="tableOptions"
    :table-data="tableData"
    @gird-handle-select-click="selectionChange"
    :table-loading="tableLoading"
    :data-total="page.total"
    :page="page"
    @page-current-change="onLoad"
    @page-size-change="onLoad"
    @page-refresh-change="onLoad"
    @grid-row-detail-click="rowDetail"
    @row-remove="rowRemove"
    @unline="unline"
  >
  </grid-layout>
</div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import {mapGetters} from "vuex";
import {getList,unline} from "@/api/system/onlineUser";

export default {
  name: "mediumscreen",
  components: {
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      selectionList: [],
      tableLoading: true,
      tableData: [],
      dataTotal: null,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    searchColumns() {
      return [
        {
          prop: "userName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.onlineUser.field.username"),
        },
      ]
    },
    tableOptions() {
      return {
        height: 0,
        customAddBtn: true,
        column: [
          {
            label: this.$t('cip.plat.sys.onlineUser.field.username'),
            prop: 'user_name',
            overHidden: true,
            width: 260,
            align: "center",
          },
          {
            label: this.$t('cip.plat.sys.onlineUser.field.realname'),
            prop: 'real_name',
            overHidden: true,
            width: 260,
            align: "center",
          },
          {
            label: this.$t('cip.plat.sys.onlineUser.field.account'),
            prop: 'account',
            align: "center",
            overHidden: true,
            width: 260,
          },
          {
            label: this.$t('cip.plat.sys.onlineUser.field.login_time'),
            prop: 'login_time',
            align: "center",
            overHidden: true,
            width: 200,
          },
          {
            label: this.$t('cip.plat.sys.onlineUser.field.client_ip'),
            prop: 'client_ip',
            overHidden: true,
            align:'center',
          },
        ],
      }
    },
    gridRowBtn() {
      let   buttonBtn = [];
      if (this.permission.onlineUser_unline) {
        buttonBtn.push(
          {
            label: this.$t('cip.plat.sys.onlineUser.btn.unlineBtn'),
            emit: "unline",
            type: "button",
            icon: ""
          });
      }
      return  buttonBtn;
    },
  },
  mounted() {
    this.onLoad(this.page, {});
  },
  methods: {
    selectionChange(list) {
      this.selectionList = list;
      console.log(this.selectionList)
    },
    unline(row) {
      this.$confirm(this.$t('cip.plat.sys.onlineUser.msg.unline_tip'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(()=>{
          unline(row.token).then(res=>{
            if(res.data.code == 200){
              this.$message({
                type: "success",
                message: this.$t('cip.cmn.msg.success.operateSuccess')
              });
            }
          })
        })
        .then(() => {
          this.onLoad(this.page);
        });
    },
    gridHeadSearch(query) {
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params )
      ).then(res => {
        const data = res.data.data;
        this.tableData = data.records
        this.$refs.gridLayOut.page.total = data.total;
        this.tableLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
